* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  /* SCROLLBAR STYLE */
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    margin: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b9babe;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

body {
  font-family: "Satoshi", sans-serif;
  background-color: #fafafa;
}

.two-col-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 60px;
  justify-items: center;
}

@media only screen and (max-width: 1500px) {
  .two-col-grid {
    grid-template-columns: 1fr;
    row-gap: 60px;
  }
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
